.dropdown {
  position: relative;
  display: inline-block;
  padding-right: 100%;
}

.dropdown-icon {
  position: absolute;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  width: 40px;
  /* height: -190px; */
  margin: 6px;
  margin-top: -30px;
  margin-left: 100px;
}
