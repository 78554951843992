

.spacer {
  width: 50px;
  display: inline-block;
}

.popupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 20px;
  max-width: 80%;
  width: 90%;
  max-height: 80%;
}

.videoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  transform: translate(10%, 80%);
}

.custom-div {
  position: relative;
  justify-content: center;
  align-items: center;
  background-color:black;
  height: 100vh;
  padding: 3%;
}

.recVideoCancel {
  width: 50%;
  padding: 10px;
  background-color: rgb(234,67,53);
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30%;
  margin-left: 30%;
}

@media (max-width: 768px) {
  .custom-div {
    flex-direction: column;
  }
}
@media (height: 929px) {
  .custom-div {
    padding: 100px;
  }
}

@media (min-width: 768px) {
  #startRecordingButton {
    display: inline-block;
  }
}


.dialog-button1 {
  background-color: rgb(234,67,53);
  border-radius: 7px;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  color: black;
  cursor: pointer;
  font-size: 14px; 
  line-height: 1; 
  outline: none;
}

.dialog-button1:last-child {
  margin-right: 0;
}
.recruitment{
  height: 100%;
  background-color: black;

}
.transparent-overlay3 {
  position: fixed;
  display: grid;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; 
}
.requestcard {
  position: relative;
  justify-content: center;
  align-items: center;
  top: 40%;
  left: 35%;
  width: 30%;
  height: 27%;
}
.Loginbutton3{
  display: flex;
  justify-content:space-evenly;
  padding: 20px 0 0 0;
}
.Iconstyle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-radius: 6%;
}
/* .loader-container {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #333;
} */


/* .notification{
  font-size: 24px;
  color: white;
  margin-top: 10%;
  margin-bottom: 20%;
} */

/* .spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */