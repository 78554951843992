.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  padding: 16px;
  /* background-color: #00008B; */
}
.recr1 {
  background-color: black;
  height: 93vh;
  overflow: auto;
}



.listItem {
  background-color: white;
  border-radius: 4px;
}
.listItemrec{
  background-color: white;
  border-radius: 4px;
}

.listItem:hover {
  background-color: #bababa;
  cursor: pointer;
}
.text1 {
  color: white;
  font-size: 33px;
  font-weight: bold;
  margin-left: 40%;
}

@media (max-width: 768px) {
  .text1 {
    font-size: 33px;
    margin-left: 10%;
  }
}

@media (max-width: 480px) {
  .text1 {
    font-size: 33px;
    margin-left: 5%;
  }
}

.loader {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -5%;
}

.popup2 {
  position: absolute;
  top: 50%;
  left: 99%;
  background-color: white;
  height: 73%;
  width: 95%;
  border: 1px solid #ccc;
  
}

.popupChat {
  position: absolute;
  top: 8%;
  left: 5%;
  background-color: rgb(5, 5, 5);
  height: 89%;
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.popupChat-Container{
  border-radius: 10px;
  display: flex;
  width: 100%;
  height: 100%;
}

.Lists{
  overflow: hidden;
  border-radius: 10px;
  /* background-color: red; */
  width: 30%;
  height: 100%;
  border-right: 1px solid #ccc;
  /* margin-right: 5px; */
  padding: 5px;
}
.Chats{
  display: flex;
  border-radius: 10px;
  background-color: black;
  width: 70%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 5px;
}


.ListsConatiner{
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ListTitle{
  display: flex;
  border-radius: 10px;
  background-color:grey;
  width: 100%;
  height: 10%;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border: 1px solid white
}
.ListTitle1{
  width: 15%;
  /* margin: 10px; */
  padding: 10px;
}
.ListTitle2{
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 10px;
}

.ListBody{
  overflow-x:hidden;
  /* overflow-y: scroll; */
  border-radius: 10px;
  /* background-color:yellow; */
  width: 99%;
  height: 90%;
  overflow:hidden;
}

.ListBody:hover{overflow-y:auto;}



.transparent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: transparent; 
  z-index: 9999; 
}

.badge {
  position: absolute;
  top: 10px;
  left: 43px;
  border: 1px solid black;
 }
 .chat-bubble {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  max-width: 80%;
  word-wrap: break-word;
 
}

@media (max-width: 768px) {
  .chat-bubble {
    max-width: 70%;
  }
}

@media (max-width: 570px) {
  .chat-bubble {
    max-width: 90%;
  }
}

@media (max-width: 667px) {
  .chat-bubble {
    max-width: 80%;
  }
}

.talk-bubble { 
	margin: 5px;
  position: relative;
	max-width: 80%;
	height: auto;
  margin-left: 20px;
  border-radius: 5px;
	background-color: #2BAE66FF;
  z-index: 9999;
}
@media (max-width: 768px) {
  .talk-bubble  {
    max-width: 70%;
  }
}

@media (max-width: 570px) {
  .talk-bubble {
    max-width: 90%;
  }
}

@media (max-width: 667px) {
  .talk-bubble {
    max-width: 80%;
  }
}
.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -20px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 22px solid;
	border-color: #2BAE66FF transparent transparent transparent;
}
.talktext{
  padding: 1em;
	text-align: left;
  line-height: 1.5em;
}
.talktext p{
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
.time{
  font-size: 12px;
  margin-top: -20px;
  z-index: 999;
  margin-left: 80%;
}
.link {
  word-wrap: break-word;
  /* display:flex; */
  width: 200%;
  color: #12da27; /* Customize the link color */
  text-decoration: underline;
}

.transparent-overlay-chat {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; 
  z-index: 9999; 
}

.close{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}



.chatTitle{
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
  margin: 10px 10px 10px 0px;
  background-color: gray;
  border: 1px solid white;
  border-radius: 10px;
}

.chatBody{
  height: 80%;
  width: 100%;
  background-color:black;
  border-radius: 10px;
  overflow-y: scroll;
}
.chatSend{
  height: 10%;
  width: 100%;
  border-radius: 10px;
  display: grid;
  align-content: center;
}

/* .chatlistItem{
  background-color: black;
  color: white;
} */

/* Styling the avatar  */
received-chats-img {
  display: inline-block;
  width: 50px;
}

.received-msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received-msg-inbox {
  width: 75%;
}

.received-msg-inbox p {
  background: #efefef none repeat scroll 0 0;
  border-radius: 10px;
  color: #030303;
  font-size: 14px;
  /* margin-left: 1rem; */
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
}
  p {
  overflow-wrap: break-word;
}

/* Styling the msg-sent time  */
.time {
  color: #fff;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.outgoing-chats {
  overflow: hidden;
  margin: 26px 20px;
}

.outgoing-chats-msg p {
  background-color: #6c757d;;
  /* background-image: linear-gradient(45deg, #ee087f 0%, #DD2A7B 25%, #9858ac 50%, #8134AF 75%, #515BD4 100%); */
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoing-chats-msg {
      float: right;
      width: 75%;
  }

/* Styling the avatar */
.outgoing-chats-img {
  display: inline-block;
  width: 50px;
  float: right;
  /* margin-right: 1rem; */
}


/* ------- */

.Candidateclose{
  position: absolute;
  top: 10px;
  right: 10px;
}

.Candidateclose2{
  position: absolute;
  right: 10px;
  top:5%;
  width:12%
}

.CandidateChats{
  display: flex;
  border-radius: 10px;
  background-color: black;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  /* padding: 5px; */
}

.CandidatechatTitle{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  /* margin: 10px 10px 10px 0px; */
  background-color: gray;
  border: 1px solid white;
  border-radius: 10px 10px 0px 0px;
}

.CandidatechatBody{
  height: 90%;
  width: 100%;
  background-color:black;
  overflow-y: scroll;
}
.CandidatechatSend{
  height: 10%;
  width: 100%;
  border-radius: 10px;
  display: grid;
  align-content: center;
  padding: 10px;
}

.CandidateChatTop{
  background-color: red;
  height: 10%;
  border-radius: 10px
}


.CandidateChatBottom{
  height: 90%;
  border-radius: 10px
}
.chatTitle1{
  padding: 25px;
}

.search{
width: 99%;
display: flex;
align-items: center;
margin-bottom: 10px;
border-radius: 10px;
border:1px solid white
}
.searchTab1{
width:'100%';
/* height: '100%'; */
border-radius: 10px 0px 0px 10px;
}

.test{
    position: absolute;
    z-index: 9;
    left: 50%;
}



@media (max-width: 768px) {
  .popup2 {
    width: 70%;
  }
  .Chats{
    display: flex;
    border-radius: 10px;
    background-color: black;
    width: 60%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px;
  }
  .Lists{
    overflow: hidden;
    border-radius: 10px;
    /* background-color: red; */
    width: 40%;
    height: 100%;
    border-right: 1px solid #ccc;
    /* margin-right: 5px; */
    padding: 5px;
  }
  .ListTitle{
    display: flex;
    border-radius: 10px;
    background-color:grey;
    width: 100%;
    height: 10%;
    color: whitesmoke;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: 1px solid white
  }
  .ListTitle1{
    width: 10%;
    /* margin: 10px; */
    padding: 10px;
  }
  .ListTitle2{
    display: flex;
    flex-direction: column;
    width: 90%;
    /* margin: 10px; */
    padding: 30px;
  }
  .chatTitle{
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin: 10px 10px 10px 0px;
    background-color: gray;
    border: 1px solid white;
    border-radius: 10px;
  }
  .chatTitle1{
    padding: 25px;
  }
}

@media (max-width: 570px) {
  .popup2 {
    width: 90%; 
  }
  .Chats{
    display: flex;
    border-radius: 10px;
    background-color: black;
    width: 60%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px;
  }
  .Lists{
    overflow: hidden;
    border-radius: 10px;
    /* background-color: red; */
    width: 40%;
    height: 100%;
    border-right: 1px solid #ccc;
    /* margin-right: 5px; */
    padding: 5px;
  }
  .ListTitle{
    display: flex;
    border-radius: 10px;
    background-color:grey;
    width: 100%;
    height: 10%;
    color: whitesmoke;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: 1px solid white
  }
  .ListTitle1{
    width: 15%;
    /* margin: 10px; */
    padding: 10px;
  }
  .ListTitle2{
    display: flex;
    flex-direction: column;
    width: 85%;
    /* margin: 10px; */
    padding: 30px;
  }
  .received-msg-inbox {
    width: 100%;
  }
  .outgoing-chats-msg {
    float: right;
    width: 100%;
  }
  .listItemrec{
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .chatTitle{
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin: 10px 10px 10px 0px;
    background-color: gray;
    border: 1px solid white;
    border-radius: 10px;
  }
  .chatTitle1{
    padding: 25px;
  }
}

@media (max-width: 490px) {
  .popup2 {
    width: 90%; 

  }
  .Chats{
    display: flex;
    border-radius: 10px;
    background-color: black;
    width: 55%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px;
  }
  .Lists{
    overflow: hidden;
    border-radius: 10px;
    /* background-color: red; */
    width: 45%;
    height: 100%;
    border-right: 1px solid #ccc;
    /* margin-right: 5px; */
    padding: 5px;
  }
  .ListTitle{
    display: flex;
    border-radius: 10px;
    background-color:grey;
    width: 100%;
    height: 10%;
    color: whitesmoke;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: 1px solid white
  }
  .ListTitle1{
    width: 15%;
    /* margin: 10px; */
    padding: 10px;
  }
  .ListTitle2{
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 25px;
    padding: 10px;
  }
  .received-msg-inbox {
    width: 100%;
    font-size: small;
  }
  .outgoing-chats-msg {
    float: right;
    width: 100%;
    font-size: small;
  }
  .listItemrec{
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .chatTitle{
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin: 10px 10px 10px 0px;
    background-color: gray;
    border: 1px solid white;
    border-radius: 10px;
  }
  .chatTitle1{
    padding: 5px;
  }
}

@media (max-width: 350px) {
  .popup2 {
    width: 90%; 
  }
  .Chats{
    display: flex;
    border-radius: 10px;
    background-color: black;
    width: 60%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px;
  }
  .Lists{
    overflow: hidden;
    border-radius: 10px;
    /* background-color: red; */
    width: 40%;
    height: 100%;
    border-right: 1px solid #ccc;
    /* margin-right: 5px; */
    padding: 5px;
  }
  .ListTitle{
    display: flex;
    border-radius: 10px;
    background-color:grey;
    width: 100%;
    height: 10%;
    color: whitesmoke;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: 1px solid white
  }
  .ListTitle1{
    width: 15%;
    /* margin: 10px; */
    padding: 10px;
  }
  .ListTitle2{
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 10px;
    padding: 25px;
  }
  .listItemrec{
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .chatTitle{
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin: 10px 10px 10px 0px;
    background-color: gray;
    border: 1px solid white;
    border-radius: 10px;
    flex-direction: column;
  }
  .chatTitle1{
    padding: 5px;
  }
}


.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.open {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  max-width: 600px;
  position: relative;
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 28px;
  font-weight: bold;
}

.open-btn {
  background-color: rgb(234, 67, 53);
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.open-btn:hover {
  background-color: rgba(234, 67, 53, 0.8);
}

.modal-content button {
  background-color: rgb(234, 67, 53);
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.modal-content button:last-child {
  margin-bottom: 0;
}

.modal-content button:hover {
  background-color: rgba(234, 67, 53, 0.8);
}



/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}







