.container4 {
  max-width: 55%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  height: 450px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: -670px;
  z-index: 9999;


}

.container5 {
  width: 100%;
  height: 765px;
  background-color: black;
}
@media (height: 929px) {
  .container5 {
    height: 865px;
  }
}
.container4 h1 {
  font-size: 28px;
  font-weight: bold;
  margin-top: 60px;
  color: #333;
}
.location{
  margin: 20px;
  width: 450px;
}
.job{
  width: 450px;
}

.dropdown1-label {
  color:black;
  font-weight: bold;
}

.dropdown1-select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .container4 {
    max-width: 100%;
    padding: 10px;
  }
}

@media (max-height: 500px) {
  .dropdown2-container4 {
    margin-top: 10px;
  }

  .dropdown1-label {
    font-size: 14px;
  }

  .dropdown1-select {
    padding: 8px;
  }
}
.buttons2 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
 
}

.buttons2 button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #2BAE66FF;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
}

.buttons2 button:hover {
  background-color:lightgray;
}

.back-icon {
  position: absolute; /* Position the icon absolutely within the container */
  top: 0; /* Place the icon at the top */
  left: 0; /* Place the icon at the left */
  margin: 10px; /* Add some margin for spacing */
  cursor: pointer; /* Add a pointer cursor for interaction */
}
