.popup {
    position: absolute;
    width: 100%;
    height: 240%;
    left: 50%;
    margin-bottom: 130%;
    transform: translate(-50%, -50%);
    background-color: rgba(250, 248, 248, 0.8);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .reel1{
    background-color: black;
  }
  .reel111 {
    display: flex;
    flex-direction: column;
    background-color: black;
    justify-content: center;
    align-items: center;
    margin: 2.6rem;
    scroll-snap-align: start;
  }
  .toast-over-navbar {
    z-index: 9999;
    top: 70px;
    left: -60px;
    width:330px;
    height:80px;
  }
  
  
  .input-text {
    width: 100%;
    height: 110px;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
  }
  .toast-container {
    .Toastify__toast {
      background-color: #f8f8f8;
      color: black;
      font-size: 14px;
    }
  
    .Toastify__toast--success {
      background-color: #dff0d8;
      color: #3c763d;
    }
  }
  .rec-info1 {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: #fff; */
    display: flex;
    margin-top: 0.5rem;
    color: #fff;
    flex-direction: column;

  }
  .recinnerdiv1{
    display: flex;
  }

  .recinnerdiv2{
    /* margin-left: 200px; */
    display: flex;
    flex-direction: row-reverse;
  }
  
  .title {
    color: #fff;
  }
  
  .rec-info1 div span {
    margin-left: 0.5rem;
  }
  
  .rec-info1 button {
    color: black;
    background: rgb(234,67,53);
    font-weight: 600;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 7px;
    cursor: pointer;
  }
  .recr{
    height: 775px;
    background-color: #333;
   }
   .text1 {
    color: white;
    font-size: 33px;
    font-weight: bold;
    margin-left: 40%;
  }
  .text-overlay12 {
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: lightgrey;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
  }