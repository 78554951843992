.short-container111::-webkit-scrollbar {
    display: none;
  }
  
  .short-container111 {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 92vh;
    scroll-behavior: smooth;
    overflow-y: scroll;
    -webkit-scroll-snap-type: y mandatory;
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
    /* scroll-padding: 1.5rem; */
  }
  @media (max-width: 600px) {
  
    .short-container111 {
      align-items: center;
      background-color: black;
    }
  }