.dropdown {
  position: relative;
  display: inline-block;
  padding-right: 100%;
}

.dropdown-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  width: 40px;
  height: -190px;
  margin: 6px;
}

