.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #2BAE66FF

  ;
}

.close-button {
  background-color: #2BAE66FF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.close-button:hover {
  background-color: #2BAE66DD;
}

.save-button {
  background-color: #2BAE66FF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.save-button:hover {
  background-color: #2BAE66DD;
}

.buttonsss {
  display: flex; /* Use flexbox to control the spacing between buttons */
  justify-content: space-around; /* Add space between the buttons */
}

.location1{
  margin: 20px;
  width: 450px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: 0 auto;
}
.loaderContainer2 {
  display: flex;
  justify-content:center;
  align-items: center;
  height: '100vh';
  background-color: rgba(0, 0, 0, 0.5),
} 
.nav1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; /* Adjust the height as needed */
  background-color:rgb(234,67,53);
  /* Add other styling properties as per your requirements */
}
.nav2 {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15px;  
  bottom: 0%;
  width: 100%; 
  z-index: 9999;
}
.menu-button {
  margin-left: 15px;
}
.fliter{
  color:black;
  margin-left: 100px;
}

.nav p {
  margin: 0;
  cursor: pointer;
}

.left-menu {
  margin-top: 10px;
  display: flex;
  position: absolute;
  left: 0.5%;
}

.left-menu > div {
  margin-right: 10px;
}
.left-menu > div:not(:first-child) {
  margin-left: 50px; /* Adjust the spacing between menu items */
}




.left-menu1 {
  display: flex;
}

.left-menu1 > div {
  margin-right: 10px;
}
.left-menu1 > div:not(:first-child) {
  margin-left: 50px; /* Adjust the spacing between menu items */
}

.right-menu {
  margin-top: 10px;
  display: flex;
}

.right-menu > div {
  margin-left: 10px;
}

.right-menu > div:not(:first-child) {
  margin-left: 50px; /* Adjust the spacing between menu items */
}
.right-menu1 {
  display: flex;
}

.right-menu1 > div {
  margin-left: 10px;
}

.right-menu1 > div:not(:first-child) {
  margin-left: 50px; /* Adjust the spacing between menu items */
}
.drawer-paper {
  background-color: #f01;
  width: 250px;
  margin-top: 60px;
}
.drawer-wrapper {
  background-color:#f1f1f1;
  height: 100vh;
}
.nav-items-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
  margin-top: 30px;
}

.drawer-content {
  background-color: #f1f1f1;
  
}

.drawer-item {
  color: black;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 1.1em;

}


.drawer-item.active {
  background-color: #bababa;
  color: rgb(234,67,53);
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.drawer-item:hover {
  /* Styles for hover effect on drawer items */
  background-color:#bababa;
  padding: 5px;
}



.drawer-item1 {
  color: black;
  cursor: pointer;
  font-size: 1.1em;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  display: flex;
  gap: '10px';
  justify-content: flex-start;
}


.drawer-item1.active {
  /* Styles for active drawer item */
  background-color: #bababa;
  color: #f1f1f1;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.drawer-item1:hover {
  /* Styles for hover effect on drawer items */
  background-color:#bababa;
  padding: 5px;
}

.nav-bar-title1 {
  /* margin-left: -150px; */
  font-size: 22px;
  font-weight: bold;
  color: black;
}
.nav-bar-title {
  margin-left: -50px;
  font-size: 22px;
  font-weight: bold;
  color: black;
}
/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .nav {
    height: 50px; /* Adjust the height for smaller screens */
    /* Add other responsive styles as needed */
  }
}
.account,
.notification,
.recruiter,
.privacy,
.logout{

  color: black;
}
  
.logo-container {
  width: 10.5rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  height: auto;
}

.logo {
  width: 100%;
}


.main {
  display: flex;
}

aside {
  padding-top: 5rem;
  margin-left: 3rem;
}

aside ul {
  list-style: none;
  backdrop-filter: blur(10px);
  width: 280px;
  background: #15161c6e;
  border-radius: 10px;
  border: 1px solid #21232b;
  overflow: hidden;
}

.active {
  color: #ffce00;
  font-weight: 1000;
}

.active1 {
  color: #2ccc49;
  cursor: pointer;
  font-size: 2rem;
  padding-right: 15px;
}

.active2 {
  color: #2ccc49;
  cursor: pointer;
  font-size: 2rem;
  margin-left: 0;
  padding-left: 15px;
}

.active3 {
  color: #21232b;
  cursor: pointer;
  margin-left: 0;
  padding-left: 15px;
}

.jobs {
  margin-left: 0;
}

.recuiter {
  margin-right: 0;
}

.nav-help {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

aside ul li {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

aside ul li:hover {
  background: #21232b;
}

aside ul li ion-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

aside footer small {
  color: #4a4a4d;
}

.navigation-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
  right:30%; 
  top:30%
}

@media (max-width: 1420px) {
  .navigation-container  {
   right:20%
  }
}
@media (max-width: 1220px) {
  .navigation-container  {
   right:20%
  }
}
@media (max-width: 950px) {
  .navigation-container  {
   right:10%
  }
}
@media (max-width: 700px) {
  .navigation-container  {
    display: none;
   }
}

.arrow{
  background-color: black;
}

.navigation-container div {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background: #f3f4f7;
  cursor: pointer;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  border: 1px solid #63646a4f;
  left: 10px;
  top: 10px;
}

.navigation-container div:hover {
  background: #63646a4f;
}

/* .nav-up {
  margin-top: 5rem;
} */

.navigation-container1 {
  position: absolute;
  display: flex;
  /* height: 72vh; */
  flex-direction: column;
  justify-content: center;
  margin-left: 60rem;
  top: 35%;

}

@media (max-height: 768px) {
  /* Adjustments for smaller vertical size */
  .navigation-container1 {
    margin-left: 60rem;
    /* margin-top: -755px; */
  }
}
@media (max-width: 1150px) {
  /* Adjustments for smaller vertical size */
  .navigation-container1 {
    margin-left: 45rem;
    /* margin-top: -755px; */
  }
}
@media (max-width: 600px) {
  .navigation-container1 {
    display: none; /* Hide the buttons on mobile devices */
  }
}



.nav-up {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background: #f3f4f7;
  cursor: pointer;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  border: 1px solid #63646a4f;
  margin-left: 70px;
  margin-bottom: 80%;
}
.nav-down {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background: #f3f4f7;
  cursor: pointer;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  border: 1px solid #63646a4f;
  margin-left: 70px;
  margin-top: 80%;
}

.navigation-container1 div:hover {
  background: #63646a4f;
}


.short-container::-webkit-scrollbar {
  display: none;
}

.short-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 86vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  -webkit-scroll-snap-type: y mandatory;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  /* scroll-padding: 1.5rem; */
}

.reel {
  display: flex;
  flex-direction: column;
  background-color: black;
  justify-content: center;
  align-items: center;
  /* margin: 2.6rem; */
  scroll-snap-align: start;
}
.reel10{
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
}

/* .reel:last-child {
  padding-bottom: 10rem;
} */

.reel ion-icon {
  cursor: pointer;
  z-index: 999;
}

.video {
  position: relative;
  height: 86vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 10px;
  max-width: calc((9 / 16) * 80vh);
  background:#6c757d;
}

.controls {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all 0.2s;
  transform: translateY(-50px);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.734), transparent);
}

.video:hover>.controls {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.controls ion-icon {
  font-size: 1.3rem;
  cursor: pointer;
  color: #fff;
}

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  transition: all 0.2s;
  border-radius: 10px;
}

.video:hover>.foot {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
}

.foot img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.play-pause {
  position: absolute;
  top: 50%;
  cursor: pointer;
  background: #00000075;
  display: flex;
  width: 4rem;
  height: 4rem;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.show-play-pause {
  transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1 !important;
}

.play-pause ion-icon {
  font-size: 2rem;
  color: #fff;
}

.reaction {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 1rem;
}

.reaction div {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.reaction div span {
  margin-top: 5px;
}

.reaction ion-icon {
  font-size: 2rem;
}

.like {
  color: #f81348;
  animation: like 0.3s ease-in forwards;
  background: #f813482b;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlike {
  animation: unlike 0.17s ease-in reverse forwards;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 930px) {
  aside {
    margin-left: 1rem;
  }

  .navigation-container {
    margin-right: 1rem;
  }
}

@media (max-width: 870px) {
  aside {
    display: none;
  }
}

@media (max-width: 700px) {
  .search-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .reaction {
    position: absolute;
    right: 0;
    bottom: 8rem;
    color: #fff;
  }

  .reaction .value {
    font-size: 0.8rem;
  }

  .navigation-container {
    display: none;
  }

  .short-container {
    align-items: center;
    background-color: black;
  }
}

@keyframes like {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes unlike {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

ion-icon {
  pointer-events: none;
}
.container11{
  background-color: black;
  height: 100vh;
}
.popup0 {
  min-height: 150px;
}

.transparent-overlay1 {
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; 
}
.button-container1 {
  display: flex;
  justify-content: center;
}

.left-button2 {
  color: white;
  font-weight: bold;
  background-color: grey;
 left: 0;
}

.right-button3 {
  color: white;
  font-weight: bold;
  background-color:grey;
  right: 0;
}

.login-button1 {
  flex: 1;
  padding: 10px 20px;
  font-size: 22px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.Loginbutton{
  display: flex;
  justify-content: end;
  padding: 20px 0 0 0;
  text-transform: lowercase;
}

 .count{
  position: absolute;
  right: 20px;
 }



 .bell{
  display:block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin:50px auto 0;
  color: #9e9e9e;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}




 
