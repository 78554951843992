.userContainer {
  width: 50%;
  max-width: 50%;
  max-height: max-content;
  padding: 1%;
  background-color:#f2f2f2;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  flex-direction: column;
  align-items: center;
  }
  .uploadUserButton {
    margin-top: 3%;
    width: 50%;
    padding: 10px;
    background-color: #2BAE66FF;
    color: black;
    border: none;
    align-content: center;
    border-radius: 4px;
    cursor: pointer;
    flex-direction: row;
    margin-left: 10px;
}

.videoCancel {
    margin-top: 3%;
    width: 50%;
    padding: 10px;
    background-color: #2BAE66FF;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex-direction: row;
    margin-right: 10px;
}

  
  .uploadField{
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .heading {
    font-size: 24px;
    color:black ;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .usercolor{
    height:780px;
    background-color: #333;
  }
  .toast-progress {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .toast-info {
    background-color: #007bff !important;
    color: #ffffff !important;
  }
  
  .toast-body {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  
  .recording {
    width: 100%;
    padding: 10px;
    background-color: #2BAE66FF;
    color: black;
    border: none;
    border-radius: 4px;
  }
/* 
  .dialog-button {
    background-color: #2BAE66FF;
    border-radius: 7px;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    color: black;
    cursor: pointer;
    font-size: 14px; 
    line-height: 1; 
    outline: none;
  }
  
  .dialog-button:last-child {
    margin-right: 0;
  } */
  
  
  @media (min-width: 450px) {
    .largerDialog {
      max-width: 30%;
    }
  }
  