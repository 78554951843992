.apply {
    padding: 3px 5px;
    font-size: 12px;
    background-color:#2BAE66FF;
    color: black;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    display: inline-block;
  }
  .user-info button {
    background: #2BAE66FF;
    font-weight: 600;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 7px;
    cursor: pointer;
  }
  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: #fff;
  }
  .user-info div span {
    margin-left: 0.5rem;
  }
  
  
  .text-overlay12 {
    position: absolute;
    top: 10%;
    margin-left: 10%;
    color: lightgrey;
    font-size: 14px;
  }
  .notification2{
    position: fixed;
    z-index: 9999;
    color:black;
    top: 7%;
    left: 45%;
  }
  
  .text-container {
    position: relative;
    width: 240px; 
    cursor: pointer; 
  }
  .text {
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-container1 {
    cursor: pointer; 
    margin-left: 50px;
    margin-top: -30px;
  }
  .hovered {
    background-color: transparent; 
  }
  
  .popup19 {
    position: fixed;
    bottom: 100%;
    right: 0%;
    border-radius: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    display: block;
    width: 100%; 
    height: 70%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
  }