.option {
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.header {
  text-align: center;
  margin-top: 0%;
  font-size: larger;
  font-weight: bold;
}


.multiselect-container {
  position: relative;
}

.multiselect-dropdown {
  position: absolute;
  bottom: 100%; 
  left: 0;
  z-index: 1; 
  max-height: 150px; 
  overflow-y: auto; 
}
.multiSelectContainer li{
  color: black;
  list-style: none;
}
