.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}



.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: lightseagreen;
  color: #fff;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  justify-content: center;
}

.brand-name {
  text-decoration: none;
  color: rgb(249, 248, 248);
  font-size: 1.3rem;
  margin-left: 1rem;
}

.fill-window {
  height: 100%;
  width:100%;
  position: absolute;
  background-color:black;
  @media (max-width: 720px) {
    height: 118%;
  }

  @media (max-width: 560px) {
    height: 120%;
  }

  @media (max-width: 375px) {
    height: 125%;
  }
  
}
.toastify-toast {
  font-family: Arial, sans-serif;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 12px 16px;
}

.toastify-close-button {
  font-family: Arial, sans-serif;
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
}

.toastify-close-button:hover {
  text-decoration: underline;
}

.popup1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: grey;
  padding: 40px;
  height: 50%;
  width: 40%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  
}


@media (max-width: 768px) {
  .popup1 {
    width: 70%;
  }
}


@media (max-width: 480px) {
  .popup1 {
    width: 90%; 
    padding: 20px;
  }
}
@media (max-width: 570px) {
  .popup1 {
    width: 90%; 
    padding: 20px;
  }
}


.button-container {
  display: flex;
  justify-content: center;
}

.left-button {
  margin-right: 120px;
  margin-top: 50px;
  color: white;
  font-weight: bold;
  background-color: rgb(234,67,53);
  margin-right: 10px; 
}

.right-button {
  margin-left: 120px;
  margin-top: 50px;
  color: white;
  font-weight: bold;
  background-color: #2BAE66FF;
  margin-left: 10px; 
}

.login-button {
  flex: 1;
  width: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.loader2 {
  color: white;
}


.Container
{
  display: flex;
  max-width: 1600px;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 2rem;
  column-gap: 4rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.positioner{
  position: relative;
  
  @media (max-width: 1920px) {
    top: 15%;
  }

  @media (max-width: 710px) {
    top: 5%;
  }

  @media (max-width: 540px) {
    top: 5%;
  }

  @media (max-width: 375px) {
    top: 5%;
  }
  
}


.hover {
  cursor:grab;
  transform: translateY(-0.75em);
  transition: transform 200ms;
  transition: 0.3s;
}

.hover::before {
  width: 2.6em;
  opacity: 0;
  transition: opacity 200ms;
}

.hover:hover {
  transform: none;
  /*color: #fff;
  text-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4; */
}

.hover:hover::before {
  opacity: 1;
  color: #fff;
  /* text-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4; */
}

.card0 {
  max-width: 325px;
  max-height: 345px;
  min-height: 320px;
  border: 2px solid white;
  border-radius: 14px;
}

.loading-logo {
  opacity: 1;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}

.PSLoader{
  background-color: black;
    display: grid;
    justify-content: center;
    align-items: center;
}
.Loading{
  color: skyblue;
  text-align: center;
}
.loginbutton8{
  text-transform: none;
  background-color: white;
  width: 400px;
  height: 50px;
  font-size: 22px;
}
@media (max-width: 545px) {
  .loginbutton8{
    width: 300px;
  }
}
.back-icon1 {
  position: fixed; /* Position the icon absolutely within the container */
  top: 3px; /* Place the icon at the top */
  left: 0; /* Place the icon at the left */
  margin: 10px; /* Add some margin for spacing */
  cursor: pointer; /* Add a pointer cursor for interaction */
}
