
.MuiDialog-paper {
    border: none;
    box-shadow: none;
  }
  
  .MuiCard-root {
    border: none;
    box-shadow: none;
  }
  

  .card-content-container {
    display: flex;
    justify-content: center;
  }
  

  .MuiDialog-paperWidthSm {
    max-width: 600px;
  }

  .language-options-container {
    width: 100%;
  }
  
  /* .MuiListItem-root {
    padding: 8px 16px;
  } */
  

  .language-option {
    border-bottom: 1px solid #f1f1f1;
  }
  
  .dialog-paper {
    max-width: 600px;
    border-radius: 12px;
  }
  
  .language-option.selected:hover {
    height:  50px;
    color: #343435;
    background-color: lightgrey;
  }
  .transparent-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999; 
  }
  
