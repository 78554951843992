.close-button {
    background-color: #2BAE66FF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
    width: 100px;
  }
  
  .close-button:hover {
    background-color: #2BAE66DD;
  }

  .buttonsss {
    display: flex; /* Use flexbox to control the spacing between buttons */
    justify-content: space-evenly; /* Add space between the buttons */
  }