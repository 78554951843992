.popup {
    position: absolute;
    width: 100%;
    height: 240%;
    left: 50%;
    margin-bottom: 130%;
    transform: translate(-50%, -50%);
    background-color: rgba(250, 248, 248, 0.8);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .reel2{
    background-color: black;
    height: 10;
  }
  .toast-over-navbar {
    z-index: 9999;
    top: 70px;
    left: -60px;
    width:330px;
    height:80px;
  }
  .loaderContainer2 {
    display: flex;
    justify-content:center;
    align-items: center;
    height: '100vh';
    background-color: rgba(0, 0, 0, 0.5),
  }
  .loader2{
    color: white
  }
  
  
  .input-text {
    width: 100%;
    height: 110px;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
  }
  .toast-container {
    .Toastify__toast {
      background-color: #f8f8f8;
      color: #333;
      font-size: 14px;
    }
  
    .Toastify__toast--success {
      background-color: #dff0d8;
      color: #3c763d;
    }
  }
  .rec-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: #fff;
  }
  
  .title {
    color: #fff;
  }
  
  .rec-info div span {
    margin-left: 0.5rem;
  }
  
  .rec-info button {
    color: white;
    background: #2BAE66FF;
    font-weight: 600;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 7px;
    cursor: pointer;
  }
  .recr{
    height: 775px;
    background-color:black;
   }
   .text1 {
    color: white;
    font-size: 33px;
    font-weight: bold;
    margin-left: 40%;
  }

  .reel15 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 92vh;
  }