.popup2 {
  position: absolute;
  width: 100%;
  height: 270%;
  left: 50%;
  margin-bottom: 130%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 248, 248, 0.8);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reel5 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.toast-over-navbar {
  z-index: 9999;
  top: 70px;
  left: -60px;
  width: 330px;
  height: 80px;
}


.input-text {
  width: 100%;
  height: 110px;
  padding: 10px;
  font-size: 16px;
  resize: vertical;
}

.toast-container {
  .Toastify__toast {
    background-color: #f8f8f8;
    color: black;
    font-size: 14px;
  }

  .Toastify__toast--success {
    background-color: #dff0d8;
    color: #3c763d;
  }
}

.rec-info2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  color: #fff;
}

.title {
  color: #fff;
}

.rec-info2 div span {
  margin-left: 0.5rem;
}
.rec-info3 {
  margin-top: -10%;
  color: white;

}
/* .button1 {
  margin-left: 1rem;
} */
.button3 {
  margin-left: 30%;
  width: 40%;
  color: black;
  background: rgb(234,67,53);
  font-weight: 600;
  border: none;
  padding: 0.5rem;
  border-radius: 7px;
  cursor: pointer;
}
.button1 {
  margin-left: 37%;
  width: 30%;
  color: black;
  background: rgb(234,67,53);
  font-weight: 600;
  border: none;
  padding: 0.5rem;
  border-radius: 7px;
  cursor: pointer;
}
.button2 {
  /* margin-right: 37%; */
  width: 30%;
  color: black;
  background: rgb(234,67,53);
  font-weight: 600;
  border: none;
  padding: 0.5rem;
  border-radius: 7px;
  cursor: pointer;
}

.date img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%; 
  cursor: pointer; 
  /* position: relative; */
  left: 10px;
}
.text-overlay12 {
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: lightgrey;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
}
.color1{
  background-color: black;
}
.allign{
  display: flex;
   /* justify-content: center; */
   justify-content:flex-end;
}
